import { api } from "~/lib/api";
import {
    useCursorPaginationControls,
    type CursorCollection,
} from "@smakecloud/smake-use";
import { keepPreviousData } from "@tanstack/vue-query";

type ProductionMethodListSchema = typeof api.productionMethods.list.outputType;

export const useProductionProductionStore = defineStore(
    "productionProductionStore",
    () => {
        const searchPageVisited = ref<boolean>(false);
        const disableSearchWatcher = ref<boolean>(false);

        const currentCursor = ref<string>();
        const searchString = ref<string>();
        const perPage = ref<number>(10);

        const { isFetching, isError, data, error, refetch } = useQuery({
            ...api.productionMethods.list.use(
                computed(() => ({
                    cursor: currentCursor.value,
                    filter: {
                        q: searchString.value,
                    },
                    per_page: perPage.value,
                })),
            ),
            placeholderData: keepPreviousData,
        });

        const changeSearch = (search?: string) => {
            currentCursor.value = undefined;
            searchString.value = search ?? "";
        };

        return {
            searchPageVisited,
            data,
            isFetching,
            isError,
            error,
            changeSearch,
            searchString,
            currentCursor,
            ...useCursorPaginationControls(
                data as Ref<
                    CursorCollection<ProductionMethodListSchema["data"][number]>
                >,
                currentCursor,
            ),
            disableSearchWatcher,
            perPage,
            refetch,
        };
    },
);
